<template lang="pug">
  el-dialog(
    title="Ver / Editar Configuración de Facturación"
    :visible.sync="localVisible"
    width="70%"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  )
    el-row
      el-col
        label Nombre: {{ this.data.name }}
    el-row
      el-form(:model="invoicingForm" label-position="top" size="mini" ref="form")
        div(v-for="(data, index) in invoicingForm.invocingData" :key="index" class="data-card")
          el-card(v-if="country === 'CL'")
            template(#header)
              span Razón Social {{ index + 1 }}
            el-row(:gutter="20")
              el-col(:span="12")
                el-form-item(label="RUT")
                  el-input(v-model="data.national_id")
                el-form-item(label="Giro")
                  el-input(v-model="data.business_activity")
              el-col(:span="12")
                el-form-item(label="Razón Social")
                  el-input(v-model="data.legal_name")
                el-form-item(label="Dirección")
                  el-input(v-model="data.address")
            div(action-buttons)
              el-button(
                type="warning"
                @click="removeCard(index)"
                size="mini"
              ) Eliminar
          el-card(v-if="country === 'MX'")
            template(#header)
              span Razón Social {{ index + 1 }}
            el-row(:gutter="20")
              el-col(:span="12")
                el-form-item(label="RFC")
                  el-input(v-model="data.national_id")
            div(action-buttons)
              el-button(
                type="warning"
                @click="removeCard(index)"
                size="mini"
              ) Eliminar
        el-button(
          size="mini"
          type="primary"
          plain icon="el-icon-plus"
          class="add-card" @click="addCard"
        ) Agregar nueva Razón Social
        el-row(:gutter="20")
          el-card
            el-form-item(label="Dividir Facturación")
            el-col(:span="12")
              el-switch(v-model="invoicingForm.splitInvoicing")
            el-col(:span="12")
              el-form-item(label="Método:" v-if="invoicingForm.splitInvoicing")
                select-input(
                  :options="splitMethods"
                  :attribute="invoicingForm"
                  modelKey="selectSplitMethod"
                )
          el-alert(
            v-if="splitInvoicingValidate()"
            effect="dark"
            type="error"
            :closable="false"
          ) {{splitInvoicingValidateMessage}}
        div(v-for="(data, index) in invoicingForm.invocingData" class="data-card")
          el-row(style="padding-top: 10px" v-if="invoicingForm.splitInvoicing" :gutter="20")
            el-card
              el-col(v-if="country === 'CL'" :span="10")
                el-form-item(label="Seleccione Razón Social")
                  el-input(v-model="data.legal_name" disabled )
              el-col(v-if="country === 'MX'" :span="10")
                el-form-item(label="Seleccione Razón Social")
                  el-input(v-model="data.national_id" disabled )
              el-col(:span="10")
                el-form-item(label="Seleccione Operador" v-if="validateSource()")
                  select-input(
                    :options="busOperators"
                    :attribute="data"
                    modelKey="bus_operator_id"
                  )
              el-col(:span="4")
                el-form-item(label="Porcentaje")
                  el-input-number(
                    v-model="data.invoice_percent"
                    controls-position="right"
                    :min="1"
                    :max="100"
                  )
    el-row(:justify="'end'")
      el-col(:span="24" style="text-align: right")
        el-button(@click="closeDialog()") Cancelar
        el-button(
          type="primary"
          :loading="submitLoading"
          @click="confirmUpdate()"
          ) Guardar
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import FinanceApi from '@/services/apiService';

export default {
  name: 'InvoiceDataModal',
  components: {
    SelectInput,
  },
  props: {
    visible: Boolean,
    data: Object,
    country: String,
    source: String,
  },
  data() {
    return {
      localVisible: this.visible,
      splitMethods: [{ key: 'fixed_percent', value: 'Porcentaje fijo' }, { key: 'sales_percent', value: 'Porcentaje según ventas' }],
      busOperators: [],
      num: 1,
      invoicingForm: {
        invocingData: [],
        splitInvoicing: false,
        selectSplitMethod: { value: '' },
        selectBusOperator: { value: '' },
      },
      splitInvoicingValidateMessage: 'Hay más de una Razón Social creada y no se activó la división de facturación.'
      + 'En este caso todo será facturado a la primera Razón Social.',
      submitLoading: false,
    };
  },
  watch: {
    visible(newVal) {
      this.localVisible = newVal;
      if (newVal) {
        this.initializeAttributes();
      }
    },
  },
  methods: {
    initializeAttributes() {
      this.invoicingForm.invocingData = this.data.invoicing_data?.map((item) => (
        { ...item })) || [];
      this.invoicingForm.splitInvoicing = this.data.split_invoicing;
      this.invoicingForm.selectSplitMethod = this.data.split_invoicing_method;
      this.busOperators = this.data.bus_operators_hash;
    },
    addCard() {
      if (this.country === 'CL') {
        this.invoicingForm.invocingData.push({
          national_id: '',
          business_activity: '',
          legal_name: '',
          address: '',
        });
      } else if (this.country === 'MX') {
        this.invoicingForm.invocingData.push({
          national_id: '',
        });
      }

      this.splitInvoicingValidate();
    },
    removeCard(index) {
      this.invoicingForm.invocingData.splice(index, 1);
      this.splitInvoicingValidate();
    },
    validateSource() {
      // activate when defining how the flow will be for the group/operator division
      // return this.source === 'group';
      return false;
    },
    splitInvoicingValidate() {
      return this.invoicingForm.invocingData.length > 1
      && this.invoicingForm.splitInvoicing === false;
    },
    closeDialog() {
      this.$emit('close');
    },
    confirmUpdate() {
      if (this.validateDynamicFields()) {
        this.startSubmitLoading();
        this.$confirm('¿Estás seguro de que deseas modificar la Configuración de Facturación?', '¡Cuidado!', {
          confirmButtonText: 'SÍ',
          cancelButtonText: 'NO',
          type: 'warning',
        }).then(() => {
          this.updateInvoicingData();
        }).catch(() => {
          this.stopSubmitLoading();
        });
      } else {
        this.$notify({
          title: 'Error',
          message: 'Debes completar todos los datos obligatorios de las razones sociales',
          type: 'error',
        });
      }
    },
    validateDynamicFields() {
      let isValid = true;
      if (this.country === 'CL') {
        this.invoicingForm.invocingData.forEach((data) => {
          if (!data.national_id || !data.business_activity || !data.legal_name
          || !data.address || data.invoice_percent <= 0) {
            isValid = false;
          }
        });
      } else if (this.country === 'MX') {
        this.invoicingForm.invocingData.forEach((data) => {
          if (!data.national_id || data.invoice_percent <= 0) {
            isValid = false;
          }
        });
      }
      return isValid;
    },
    startSubmitLoading() {
      this.submitLoading = true;
    },
    stopSubmitLoading() {
      this.submitLoading = false;
    },
    updateInvoicingData() {
      FinanceApi.updateInvoicingData(this.data.id,
        this.invoicingForm.invocingData,
        this.invoicingForm.splitInvoicing,
        this.invoicingForm.selectSplitMethod,
        this.source).then(() => {
        this.closeDialog();
        this.$notify({
          title: 'Correcto',
          message: 'Configuración de Facturación modificada correctamente',
          type: 'success',
        });
        this.stopSubmitLoading();
      }).catch(() => {
        this.$notify({
          title: 'Error',
          message: 'Configuración de Facturación no modificada,'
          + ' revisa que los porcentajes de la división sumen 100%',
          type: 'error',
        });
        this.stopSubmitLoading();
      });
    },
  },
};
</script>

<style scoped>
.data-card {
  padding-top: 20px;
  margin-bottom: 10px;
}
.el-form-item{
  margin-bottom: 10px;
}
.el-row{
  padding-top: 10px
}
</style>
