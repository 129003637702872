<template lang="pug">
  div
    el-row
      el-col
        h3.title Facturación - {{ selectedCountryName }}
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="Fecha:")
            date-input(:inputModel="search" modelKey="date" type="month" size="mini")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click="fetchInvoices()"
            ) FILTRAR
            el-button(
              v-if="canSeeButton()"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportInvoices()"
            ) EXPORTAR
            el-button(
              v-if="canSeeButton() && this.search.country === 'CL'"
              type="success"
              icon="el-icon-download"
              @click.prevent="exportInvoicingNs()"
            ) EXPORTAR FACTURACIÓN NS
    el-row(:key="renderTable")
      el-col
        report-table(
          :headers="headers"
          :height="650"
          :paginated="false"
          :rowModel="'infinite'"
          :blockSize="50"
          :blocksInCache="50"
          :maxBlocksInCache="100"
          :datasourceRequests="1"
          @updateTable="updateTable"
        )
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import ReportTable from '@/components/tables/ReportTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

import { currencyFormatter } from '@/utils/cellFormatter';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import permissions from '@/constants/permissions';
import handleDownload from '@/utils/handleDownload';

export default {
  name: 'Invoicing',
  components: {
    SelectInput, DataTable, DateInput, ReportTable,
  },
  data() {
    return {
      renderTable: 0,
      allHeaders: [
        {
          headerName: 'Operador', field: 'conciliable_name', width: 280, filterType: 'text',
        },
        {
          headerName: 'Rut', field: 'national_id', width: 120, filterType: 'text',
        },
        {
          headerName: 'RFC', field: 'national_id_mx', width: 120, filterType: 'text',
        },
        {
          headerName: 'Razon social SII', field: 'legal_name', width: 120, filterType: 'text',
        },
        {
          headerName: 'Giro', field: 'business_activity', width: 120, filterType: 'text',
        },
        {
          headerName: 'Dirección', field: 'address', width: 120, filterType: 'text',
        },
        {
          headerName: 'Nombre del producto', field: 'product_name', width: 120, filterType: 'text',
        },
        {
          headerName: 'Descripción', field: 'description', width: 120, filterType: 'text',
        },
        {
          headerName: 'Comisión neta', field: 'commission', width: 120, filterType: 'text',
        },
        {
          headerName: 'IVA', field: 'tax_commission', width: 120, filterType: 'text',
        },
        {
          headerName: 'Comisión bruta', field: 'commission_with_tax', width: 120, filterType: 'text',
        },
      ],
      headers: [],
      search: {
        country: 'CL',
        date: DateService.getDate(),
        filters: {},
      },
      exportMessage: '',
      selectedCountryName: 'Chile',
      currentPage: 1,
      totalRows: 0,
      loading: false,
      pagination: {},
      filters: {},
      paramsApi: null,
      data: [],
      updateTable: async (params) => {
        this.paramsApi = params;
        const dataSource = {
          getRows: async (rowsParams) => {
            this.filterColumn(rowsParams.filterModel);
            this.paramsApi.api.showLoadingOverlay();
            this.data = await this.setInvoices();
            const lastRow = this.totalRows;
            rowsParams.successCallback(this.data, lastRow);
            this.paramsApi.api.hideOverlay();
          },
        };

        this.paramsApi.api.setDatasource(dataSource);
      },
      dialogForm: { visible: false, search: { timestamp: '' } },
      validForm: true,
    };
  },
  methods: {
    async setInvoices() {
      if (this._.isEmpty(this.search.month)) { this.search.month = []; }

      const response = await FinanceApi.get_invoices(this.currentPage, this.search, false);
      this.updateHeaders();
      this.totalPages = response.body.total_pages;
      this.totalRows = response.body.total_records;
      this.currentPage += 1;

      return response.body.invoices;
    },
    async fetchInvoices() {
      this.renderTable += 1;
      this.currentPage = 1;
      this.updateHeaders();
      this.updateTitle();
    },
    updateHeaders() {
      if (this.search.country === 'MX') {
        this.headers = [...this.allHeaders].filter((header) => ['Operador', 'RFC', 'Descripción', 'Comisión neta', 'IVA', 'Comisión bruta'].includes(header.headerName));
      } else {
        this.headers = [...this.allHeaders].filter((header) => header.headerName !== 'RFC');
      }
    },
    updateTitle() {
      if (this.search.country === 'MX') {
        this.selectedCountryName = 'México';
      } else {
        this.selectedCountryName = 'Chile';
      }
    },
    filterColumn(filterModel) {
      const filterPresent = filterModel && Object.keys(filterModel).length > 0;

      if (filterPresent) {
        if (Object.keys(this.search.filters).length !== Object.keys(filterModel).length) {
          this.cleanSearchFilter('error');
        }
        Object.keys(filterModel).forEach((key) => {
          const filterValue = filterModel[key];
          if (this.search.filters[key] !== filterValue.filter) {
            this.search.filters[key] = filterValue.filter;
            this.cleanSearchFilter();
          }
        });
      } else if (Object.keys(this.search.filters).length > 0) {
        this.cleanSearchFilter('error');
      }
    },
    cleanSearchFilter(status) {
      if (status === 'error') { this.search.filters = {}; }

      this.currentPage = 1;
    },
    exportInvoices() {
      this.loading = true;
      FinanceApi.get_invoices(this.currentPage, this.search, true).then(() => {
        this.exportMessage = this.$message({
          type: 'success',
          message: 'Se está generando el archivo CSV, esto puede tardar unos minutos. Al cerrar el navegador o pestaña se perderá la descarga',
          duration: 0,
          showClose: false,
        });
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    exportInvoicingNs() {
      this.loading = true;
      FinanceApi.export_invoicing_ns(this.search).then(() => {
        this.exportMessage = this.$message({
          type: 'success',
          message: 'Se está generando el archivo CSV, esto puede tardar unos minutos. Al cerrar el navegador o pestaña se perderá la descarga',
          duration: 0,
          showClose: false,
        });
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
        this.loading = false;
      });
    },
    format(value) {
      return currencyFormatter(value);
    },
    canSeeButton() {
      const role = store.state.user.role || '';
      return permissions.FinanceGroup.includes(role);
    },
  },
  channels: {
    ReportsChannel: {
      connected() { },
      rejected() { },
      received(data) {
        this.exportMessage.close();
        const blob = new Blob([data.csv_content], { type: 'text/csv' });

        const simulatedResponse = {
          blob: async () => blob,
          headers: {
            get: (header) => {
              if (header === 'content-disposition') {
                return `attachment; filename="${data.filename}.csv"`;
              }
              return null;
            },
          },
        };

        handleDownload(simulatedResponse);
      },
      disconnected() { },
    },
  },
  mounted() {
    this.$cable.subscribe({ channel: 'ReportsChannel', user_email: store.state.user.email });
  },
  destroyed() {
    this.$cable.unsubscribe({ channel: 'ReportsChannel' });
  },
  computed: {
    countries() {
      return DataService.get_countries().filter((country) => country.key !== 'PE');
    },
  },
};

</script>
