<template lang="pug">
div
  el-row
    el-col
      h3.title Unique Customers
  el-row
    el-col
      el-form(:inline="true" label-position="top" size="mini")
        el-form-item(label="Rango de fecha:")
          date-input(:inputModel="search" modelKey="dateRange" type="monthrange")
        el-form-item.filters__buttons-wrapper
          el-button(
            type="primary"
            icon="el-icon-search"
            @click.prevent="fetchUniqueCustomers()"
          ) FILTRAR
          el-button(
            type="success"
            icon="el-icon-download"
            @click.prevent="exportUniqueCustomers()"
          ) EXPORTAR
  el-row
    el-col
      data-table(
        :headers="headers"
        :items="items"
        :loading="loading"
        :shownActions="false"
        :height="1000"
      )
</template>

<script>
import DateInput from '@/components/forms/inputs/DateInput.vue';
import DataTable from '@/components/tables/DataTable.vue';

import DateService from '@/services/dateService';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';

import handleDownload from '@/utils/handleDownload';

export default {
  name: 'UniqueCustomers',
  components: { DateInput, DataTable },
  data() {
    return {
      search: {
        dateRange: [
          DateService.addDate(-1, 'year'),
          DateService.getDate(null, 'YYYY-MM-DD'),
        ],
      },
      headers: [
        { key: 'month', label: 'Month' },
        { key: 'active_customers', label: 'Active Customers' },
        { key: 'returning_customer_percentage', label: 'Returning Customer Percentage' },
      ],
      items: [],
      loading: false,
    };
  },
  beforeMount() {
    this.fetchUniqueCustomers();
  },
  methods: {
    fetchUniqueCustomers() {
      this.loading = true;
      FinanceApi.get_unique_customers(this.search).then((data) => {
        this.items = data.body;
        this.loading = false;
      }).catch((exception) => {
        this.loading = false;
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    exportUniqueCustomers() {
      FinanceApi.get_unique_customers(this.search, true).then((res) => {
        handleDownload(res);
      }).catch((exception) => {
        this.$notify.error({
          title: 'Error',
          message: exception.body.message,
        });
      });
    },
  },
};
</script>
