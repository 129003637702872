<template lang="pug">
  div
    el-row
      el-col
        h3.title Netsuite - Bill/Credit
    el-row
      el-col
        el-form(:inline="true" label-position="top" size="mini")
          el-form-item(label="Rango de Fecha:")
            date-input(:inputModel="search" modelKey="dateRange" type="daterange")
          el-form-item(label="País:")
            select-input(:options="countries" :attribute="search" modelKey="country")
          el-form-item(label="Formato de Fecha:")
            select-input(:options="formatDate" :attribute="search" modelKey="formatDate")
          el-form-item(label="Tipo de Formato:")
            select-input(:options="formatType" :attribute="search" modelKey="formatType")
          el-form-item(label="Mostrar columnas NS:")
            el-checkbox(
              v-model="search.nsColumns") {{ search.nsColumns ? 'Si' : 'No' }}
          el-form-item.filters__buttons-wrapper
            el-button(
              type="primary"
              icon="el-icon-search"
              @click.prevent="fetchNetsuite()"
            ) FILTRAR
          el-form-item.filters__buttons-wrapper
            el-button(
              type="default"
              icon="el-icon-refresh"
              @click.prevent="handleUpdateReportDialog()"
            ) ACTUALIZAR NETSUITE REPORT
          el-form-item.filters__buttons-wrapper
            el-dropdown(
              @command="exportFile",
              :disabled="isExportDisabled",
              style="margin: 0 12px 0 12px")
              el-button(
                type="success"
                Dropdown
              ) EXPORTAR ARCHIVO
                i(class="el-icon-arrow-down el-icon--right")
              el-dropdown-menu(slot="dropdown")
                el-dropdown-item(command="bill") Bill File
                el-dropdown-item(command="credit") Credit File
                el-dropdown-item(command="payment") Payment File
          el-form-item.filters__buttons-wrapper
            el-dropdown(
              @command="importFile",
              style="margin: 0 12px 0 12px")
              el-button(
                type="warning"
                Dropdown
              ) IMPORTAR ARCHIVO
                i(class="el-icon-arrow-down el-icon--right")
              el-dropdown-menu(slot="dropdown")
                el-dropdown-item(command="credit") Credit NS File
                el-dropdown-item(command="payment") Payment NS File
    el-row(:key="renderTable")
      el-col
        report-table(
          v-loading="loading"
          :headers="headers"
          :height="650"
          :paginated="false"
          :rowModel="'infinite'"
          :blockSize="100"
          :blocksInCache="100"
          :maxBlocksInCache="100"
          :datasourceRequests="1"
          @updateTable="updateTable"
        )
    el-dialog(
      :title="updateReportDialog.title"
      :visible.sync="updateReportDialog.visible"
      :close-on-click-modal="false"
      :show-close="false")
      el-form(:inline="true")
        el-row
          el-form-item(label="Fecha:")
            date-input(:inputModel="updateReportDialog" modelKey="dateRange" type="daterange")
      el-row.inline-form__actions
        el-button(
          @click="closeUpdateReportDialog"
        ) Cancelar
        el-button(
          type="warning"
          @click="updateReport"
        ) Actualizar
    el-dialog(
      :title="uploadDialog.title"
      :visible.sync="uploadDialog.visible"
      :close-on-click-modal="false"
      @close="closeUploadDialog")
      el-form(:inline="true" :model="uploadDialog" ref="form")
        el-row
          el-col
            el-form-item(label="Selecciona un archivo:" prop="file")
              el-upload(
                v-model="uploadDialog.file"
                action=""
                accept=".csv"
                :limit="1"
                :file-list="uploadDialog.file"
                :auto-upload="false"
                :on-change="handleChangeFile"
              )
                el-button(slot="trigger" size="small" type="primary") Buscar
        el-row
          el-col
            el-form-item(v-if="uploadDialog.type == 'credit'" label="Fechas:" prop="dateRange")
              date-input(:inputModel="uploadDialog" modelKey="dateRange" type="daterange")
        el-row.inline-form__actions
          el-button(
            type="warning"
            icon="el-icon-upload2"
            @click.prevent="uploadNetsuiteFile()"
            :loading="loading"
          ) Importar
</template>

<script>
import SelectInput from '@/components/forms/inputs/SelectInput.vue';
import DataTable from '@/components/tables/DataTable.vue';
import ReportTable from '@/components/tables/ReportTable.vue';
import DateInput from '@/components/forms/inputs/DateInput.vue';

// eslint-disable-next-line import/no-cycle
import FinanceApi from '@/services/apiService';
import DataService from '@/services/dataService';
import DateService from '@/services/dateService';
import store from '@/store';
import handleDownload from '@/utils/handleDownload';

export default {
  name: 'BillCredit',
  components: {
    SelectInput, DataTable, DateInput, ReportTable,
  },
  data() {
    return {
      renderTable: 0,
      allHeaders: [
        { headerName: 'Fecha', field: 'date', filterType: 'text' },
        { headerName: 'Operador', field: 'conciliable_name', filterType: 'text' },
        { headerName: 'Total prec. Operador', field: 'operator_price', filterType: 'text' },
        {
          headerName: 'Comisión + IVA', field: 'commission_with_tax', filterType: 'text',
        },
        { headerName: 'Recorrido fee neto', field: 'recorrido_fee_without_tax', filterType: 'text' },
        { headerName: 'Recorrido fee IVA', field: 'tax_recorrido_fee', filterType: 'text' },
        { headerName: 'Descuentos', field: 'discounts', filterType: 'text' },
        {
          headerName: 'Total Devolucion', field: 'devolution', filterType: 'text',
        },
        { headerName: 'Total', field: 'devolution_total', filterType: 'text' },
        { headerName: 'Fecha de Transferencia', field: 'transfer_date', filterType: 'text' },
        { headerName: 'Número de Transferencia', field: 'transfer_operation_number', filterType: 'text' },
      ],
      headers: [],
      columns: [],
      search: {
        country: 'CL',
        dateRange: [
          DateService.addDate(-1, 'day', null),
          DateService.addDate(-1, 'day', null),
        ],
        formatDate: 'daily',
        formatType: 'all',
        nsColumns: false,
        force: false,
        filters: {},
      },
      updateReportDialog: {
        visible: false,
        message: '',
        title: 'Actualizar NetSuite Report',
        dateRange: [
          DateService.addDate(-1, 'day', null),
          DateService.addDate(-1, 'day', null),
        ],
      },
      uploadDialog: {
        visible: false,
        type: '',
        title: '',
        file: [],
        dateRange: [
          DateService.addDate(-1, 'day', null),
          DateService.addDate(-1, 'day', null),
        ],
      },
      loading: false,
      pagination: {},
      paramsApi: null,
      data: [],
      currentPage: 1,
      totalRows: 0,
      importMessage: '',
      updateTable: async (params) => {
        this.paramsApi = params;
        const dataSource = {
          getRows: async (rowsParams) => {
            this.filterColumn(rowsParams.filterModel);
            this.paramsApi.api.showLoadingOverlay();
            this.data = await this.setNetsuite();
            const lastRow = this.totalRows;
            rowsParams.successCallback(this.data, lastRow);
            this.paramsApi.api.hideOverlay();
          },
        };
        this.paramsApi.api.setDatasource(dataSource);
      },
    };
  },
  methods: {
    async setNetsuite() {
      if (this._.isEmpty(this.search.dateRange)) { this.search.dateRange = []; }

      const response = await FinanceApi.get_netsuite(this.currentPage, this.search, false);
      this.updateHeaders();
      this.totalPages = response.body.total_pages;
      this.totalRows = response.body.total_records;
      this.currentPage += 1;
      this.loading = false;
      return response.body.records;
    },
    async fetchNetsuite() {
      this.renderTable += 1;
      this.currentPage = 1;
      this.search.force = false;
      this.data = [];
      this.updateHeaders();
    },
    updateHeaders() {
      if (this.search.formatType === 'bill') {
        let columns = ['date', 'conciliable_name', 'operator_price', 'commission_with_tax', 'recorrido_fee_without_tax', 'tax_recorrido_fee'];

        if (this.search.country === 'MX') { columns = ['date', 'conciliable_name', 'operator_price', 'commission_with_tax']; }

        this.headers = this.allHeaders.filter((header) => columns.includes(header.field));
      } else if (this.search.formatType === 'credit') {
        this.headers = this.allHeaders.filter((header) => ['date', 'conciliable_name', 'discounts', 'devolution', 'devolution_total'].includes(header.field));
      } else if (this.search.formatType === 'payment') {
        this.headers = this.allHeaders.filter((header) => ['date', 'conciliable_name', 'transfer_date', 'transfer_operation_number'].includes(header.field));
      } else {
        this.headers = [...this.allHeaders];
        if (this.search.country === 'MX') {
          this.headers = this.headers.filter((header) => !['recorrido_fee_without_tax', 'tax_recorrido_fee'].includes(header.field));
        }
      }

      this.nsColumnsChange();
    },
    filterColumn(filterModel) {
      const filterPresent = filterModel && Object.keys(filterModel).length > 0;
      if (filterPresent) {
        if (Object.keys(this.search.filters).length !== Object.keys(filterModel).length) {
          this.cleanSearchFilter('error');
        }
        Object.keys(filterModel).forEach((key) => {
          const filterValue = filterModel[key];
          if (this.search.filters[key] !== filterValue.filter) {
            this.search.filters[key] = filterValue.filter;
            this.cleanSearchFilter();
          }
        });
      } else if (Object.keys(this.search.filters).length > 0) {
        this.cleanSearchFilter('error');
      }
    },
    cleanSearchFilter(status) {
      if (status === 'error') { this.search.filters = {}; }

      this.currentPage = 1;
    },
    exportFile(command) {
      if (command === 'bill') {
        this.search.formatType = 'bill';
      } else if (command === 'credit') {
        this.search.formatType = 'credit';
      } else if (command === 'payment') {
        this.search.formatType = 'payment';
      }

      FinanceApi.get_netsuite(this.currentPage, this.search, true).then((res) => {
        this.$message({ type: 'success', message: 'Se esta generando el reporte' });

        handleDownload(res);
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    importFile(command) {
      if (command === 'credit') {
        this.uploadDialog.title = 'Subir archivo CSV de Credit NS File';
        this.uploadDialog.type = 'credit';
      } else if (command === 'payment') {
        this.uploadDialog.title = 'Subir archivo CSV de Payment NS File';
        this.uploadDialog.type = 'payment';
      }
      this.uploadDialog.visible = true;
    },
    closeUploadDialog() {
      this.uploadDialog.visible = false;
      this.uploadDialog.file = [];
      this.uploadDialog.dateRange = [
        DateService.addDate(-1, 'day', null),
        DateService.addDate(-1, 'day', null),
      ];
    },
    handleChangeFile(file) {
      this.uploadDialog.file = [];
      this.uploadDialog.file.push(file.raw);
    },
    async uploadNetsuiteFile() {
      if (this.uploadDialog.file.length === 0) {
        this.$notify.error({ title: 'Error', message: 'Debe seleccionar un archivo' });
        return;
      }
      if (this.uploadDialog.dateRange === null) {
        this.$notify.error({ title: 'Error', message: 'Debe seleccionar una fecha' });
        return;
      }

      this.loading = true;
      let data = '';

      if (this.uploadDialog.type === 'credit') { data = this.formData('import_credit'); }
      if (this.uploadDialog.type === 'payment') { data = this.formData('import_payment'); }

      FinanceApi.import_netsuite(data).then(() => {
        this.importMessage = this.$message({
          type: 'success',
          message: 'Se está generando el archivo CSV, esto puede tardar unos minutos. Al cerrar el navegador o pestaña se perderá la descarga',
          duration: 0,
          showClose: false,
        });
        this.closeUploadDialog();
        this.loading = false;
      }).catch((exception) => {
        this.$notify.error({ title: 'Error', message: exception.body.message });
      });
    },
    handleUpdateReportDialog() {
      this.updateReportDialog.visible = true;
    },
    updateReport() {
      this.updateReportDialog.visible = false;

      if (this.updateReportDialog.dateRange === '') {
        this.$notify.error({ title: 'Error', message: 'Debe seleccionar un rango de fechas' });
        return;
      }

      this.search.force = true;

      FinanceApi.update_netsuite_report(this.search).then(() => {
        this.$message({
          type: 'success',
          message: 'Se esta realizando una actualización del reporte, por favor refrescar la vista al terminar',
          duration: 15000,
        });
        this.closeUpdateReportDialog();
      });
    },
    closeUpdateReportDialog() {
      this.updateReportDialog.visible = false;
      this.updateReportDialog.dateRange = [
        DateService.addDate(-1, 'day', null),
        DateService.addDate(-1, 'day', null),
      ];
    },
    formData(importType) {
      const formData = new FormData();
      formData.append('from', this.uploadDialog.dateRange[0]);
      formData.append('to', this.uploadDialog.dateRange[1]);
      formData.append('country', this.search.country);
      formData.append('file', this.uploadDialog.file[0]);
      formData.append('import_type', importType);

      return formData;
    },
    nsColumnsChange() {
      if (this.search.nsColumns) {
        this.headers.push(
          { headerName: 'Internal ID', field: 'internal_id', filterType: 'text' },
          { headerName: 'External ID', field: 'external_id', filterType: 'text' },
          { headerName: 'CF: Transaction Unique ID', field: 'transaction_unique_id', filterType: 'text' },
          { headerName: 'CF: Bill Amount', field: 'bill_amount', filterType: 'text' },
          { headerName: 'CF: Currency', field: 'currency', filterType: 'text' },
          { headerName: 'PF: Amount', field: 'transfer_amount', filterType: 'text' },
          { headerName: 'PF: Purchase ID (Text)', field: 'purchase_id' },
        );
      }
    },
  },
  channels: {
    ReportsChannel: {
      connected() { },
      rejected() { },
      received(data) {
        this.importMessage.close();
        const blob = new Blob([data.csv_content], { type: 'text/csv' });

        const simulatedResponse = {
          blob: async () => blob,
          headers: {
            get: (header) => {
              if (header === 'content-disposition') {
                return `attachment; filename="${data.filename}.csv"`;
              }
              return null;
            },
          },
        };

        handleDownload(simulatedResponse);
      },
      disconnected() { },
    },
  },
  mounted() {
    this.$cable.subscribe({ channel: 'ReportsChannel', user_email: store.state.user.email });
  },
  destroyed() {
    this.$cable.unsubscribe({ channel: 'ReportsChannel' });
  },
  computed: {
    countries() {
      return DataService.get_countries();
    },
    formatDate() {
      return DataService.FormatDate();
    },
    formatType() {
      return DataService.FormatType();
    },
    isExportDisabled() {
      return this.search.country === 'PE';
    },
  },
};
</script>
